// To override main css use !important after variables
// After that compile main.sass

$primary-bootstrap-button: $primary-color
$primary-bootstrap-button-text: $light-color !important
$plus-minus-color: $dark-color !important

//ACCORDIONS VARIABLE
$accordion-button-color: $bg-color !important
$accordion-button-text-color: $primary-color !important

//ALERT
$position-fixed: fixed !important
$max-z-index: 999 !important

//HEADER
$header-background-links: $primary-color
$header-links-color: $text-color
$header-welcome-text-color: $dark-color
$header-cart-counter-color: $red-color
$mobile-main-navigation-background: $light-color
$mobile-main-navigation-color: $dark-color

//FOOTER
$footer-background-color: $dark-color !important
$footer-color: $text-color !important

//SIDEBAR
$sidebar-text-color: $text-color
$sidebar-on-hover-color: $primary-color

//CART COMPONENT
$cart-header-background: $light-color !important
$cart-header-total-color: $primary-color !important
$cart-lnkCartUpdate-background: $warning-bootstrap-color !important
$cart-lnkCartContinueShopping-background: $blue-bootstrap-color !important
$cart-lnkCartClearCart-background: $danger-bootstrap-color !important

//Wish list
$wish-list-header-background-color: $light-color
$wish-list-header-total-color: $light-color

$quick-order-remove-product-icon-color: $red-color
$quick-order-cancel-button-color: $dark-color
